<template>
  <div>
    <div class="vx-row">
   <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl">
        {{ $t("HospitalDoctor") }}
      </p>
    </div>

    <vs-card class="mt-5">
      <div class="vx-row " style="width:80%;margin:auto" >
            <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5 mb-4 mt-4">
            </div>
        <span class="mt-6 mr-5 ml-5" style="font-weight: bold; font-size: 15px;">{{ $t("DoctorSearch") }}</span>
        <div class="w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4 mt-4">
          <vs-input
            name="DoctorName"
            :placeholder="$t('DoctorName')"
            v-model="search.DoctorName"
          />
        </div>
      </div>
    </vs-card>

    <div class="">
      <div class="vx-row"
       @click="$router.push('/DoctorProfile/' + tr.DoctorID)"
        style="width:80%;margin:auto;cursor:pointer"
        v-for="(tr, index) in doctors"
        :key="index"
      >
        <div
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
            class="mt-4 vx-col lg:w-1/3"
        >
          <div>
           
            <imageLazy
                      :imageStyle="{ 'border-radius': '20px','width':'40px','height':'40px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + tr.ProfileImagePath"
                      placeHolderType="doctor"
                    />
          </div>

          <u>{{ $t("Dr") + "/" }}
             <span v-if="tr.FullName"> {{ tr.FullName }} </span>
             <span v-else> {{ tr.DoctorName }} </span>

            </u>
        </div>

        <div
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
          class="mt-4 vx-col lg:w-1/3">
          <div style="color:#d4d3d2"> {{ $t("Specialties") }} </div>
           <div class="vx-col lg:w-1/2" v-for="item in tr.specilties" :key="item.ID">
                 {{item.HospitalSpecialty.Specialty.Name}}
           </div>
        </div>

        <div
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
          class="mt-4 vx-col lg:w-1/3">
          <span style="color:#d4d3d2"> {{ $t("SubSpecialties") }} </span>
            <br/>
           <div v-for="item in tr.SubSpecilties" :key="item.ID">
                 {{item.HospitalSpecialty.Specialty.Name}}
           </div>
        </div>

        <vs-divider class="w-full" />
      </div>
    </div>

    <vs-card>
      <CustomPaging
        @GoNext="GoNext"
        @GoBack="GoBack"
        :CurrentPage="CurrentPage"
        :CountPerPage="CountPerPage"
        :ModelLength="this.doctorList.length"
        :ViewLastNavigation="true"
        @GoToLastPage="GoToLastPage"
        @GoToFirstPage="GoToFirstPage"
      />
    </vs-card>
  </div>
</template>
<script>
import CustomPaging from "../../components/CustomPaging.vue";
import HospitalModule from "@/store/hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    CustomPaging,
    imageLazy
  },
  data() {
    return {
      EnglishLan:false,
      search: {},
      CurrentPage: 1,
      baseURL: domain,
      doctorList:[],
      CountPerPage:7
    };
  },
  methods: {
    SearchDoctor() {},
    back() {
      this.$router.go(-1);
    },
    GoToFirstPage() {
      this.CurrentPage = 1;
    },
    GoToLastPage() {
      this.CurrentPage = Math.ceil(this.doctors.length / this.CountPerPage);
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },

    GoNext() {
      this.CurrentPage = this.CurrentPage + 1;
    },
  },
  computed: {
    doctors() {
      return this.doctorList.filter(ele=>
          ele.DoctorName.includes(this.search.DoctorName) || !this.search.DoctorName
      ).slice((this.CurrentPage-1)*this.CountPerPage,(this.CurrentPage-1)*this.CountPerPage+this.CountPerPage);
    },
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    this.$store.dispatch("HospitalList/SearchHospitalDoctors", {
      HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
    }).then(res=>{
      debugger
        var model = res.data.Data;
        var doctorLists=[];
        model.forEach(ele => {
            if(doctorLists.findIndex(r=>r.DoctorID==ele.Doctor.ID)==-1)
            {
                var obj={};
                obj.DoctorName = ele.Doctor.Name;
                 obj.FullName = ele.Doctor.FullName;
                obj.DoctorID =  ele.Doctor.ID;
                obj.ProfileImagePath = ele.Doctor.ProfileImagePath;

                obj.specilties=model.filter(b=>
                    b.Doctor.ID == ele.Doctor.ID && !b.HospitalSpecialty.Specialty.ParentSpecialtyID
                );
                obj.SubSpecilties=model.filter(b=>
                    b.Doctor.ID == ele.Doctor.ID && b.HospitalSpecialty.Specialty.ParentSpecialtyID
                );
                doctorLists.push(obj);
            }
        });
        this.doctorList=doctorLists;
    });
  },
};
</script>
